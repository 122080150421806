<template>
    <div class="c-filterrange">
        <el-popover
            placement="bottom"
            trigger="click"
            :width="320"
            @hide="save()"
        >
            <el-button :class="{ '-selected': isSelected }" slot="reference">{{
                label
            }}</el-button>
            <el-row class="margin-bottom" type="flex">
                <el-col :sm="10">
                    <el-input
                        type="number"
                        :min="min"
                        :max="max - 1"
                        step="1"
                        placeholder="von"
                        v-model="values[0]"
                    ></el-input
                ></el-col>
                <el-col :sm="4"><div class="c-line -range"></div></el-col>
                <el-col :sm="10"
                    ><el-input
                        type="number"
                        :min="min + 1"
                        :max="max"
                        step="1"
                        placeholder="bis"
                        v-model="values[1]"
                    ></el-input>
                </el-col>
            </el-row>
            <vue-slider
                v-model="values"
                :enable-cross="false"
                :min="min"
                :max="max"
                tooltip="none"
                class="margin-bottom"
                :silent="true"
            ></vue-slider>
            <el-row type="flex">
                <el-col>
                    <el-button
                        v-if="isSelected"
                        class="center"
                        @click="setup()"
                        type="text"
                        >Zurücksetzen</el-button
                    >
                </el-col>
                <el-col>
                    <el-button
                        :disabled="
                            previousValues[0] == values[0] &&
                                previousValues[1] == values[1]
                        "
                        class="center"
                        @click="save()"
                        type="primary"
                        >Speichern</el-button
                    >
                </el-col>
            </el-row>
        </el-popover>
    </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
    components: {
        VueSlider
    },
    props: {
        label: {
            type: String,
            required: true
        },
        min: {
            type: Number,
            required: true
        },
        max: {
            type: Number,
            required: true
        },
        toValue: {
            required: true
        }
    },
    watch: {
        min() {
            this.setup();
        },
        max() {
            this.setup();
        },
        toValue(newVal) {
            if(this.max !== newVal){
                this.setup(newVal);
            }
        }
    },
    data() {
        return {
            values: [0, 1],
            previousValues: [0, 1]
        };
    },
    created() {
        this.setup();
    },
    methods: {
        setup(toValue) {
            this.$set(this.values, 0, this.min);
            this.$set(this.values, 1, toValue || this.max);
            this.$set(this.previousValues, 0, this.values[0]);
            this.$set(this.previousValues, 1, this.values[1]);
            this.$emit("input", this.values);
        },
        save() {
            this.$set(this.previousValues, 0, this.values[0]);
            this.$set(this.previousValues, 1, this.values[1]);
            this.$emit("input", this.values);
        }
    },
    computed: {
        isSelected() {
            let result =
                this.min != this.values[0] || this.max != this.values[1];
            this.$emit("is-selected", result);
            return result;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-filterrange {
    display: inline-block;

    .-selected {
        border-color: black;
        font-weight: bold;
    }
}
</style>
