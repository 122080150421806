<template>
    <div id="app" :class="{ 'l-container': $env === 'development' }">
        <router-view />
    </div>
</template>

<script>
import { setup } from "@/mixins/setup";

export default {
    mixins: [setup],
};
</script>

<style lang="scss">
// #app {
//     font-family: "Avenir", Helvetica, Arial, sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     // text-align: center;
//     color: #2c3e50;
// }
</style>
