import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const hostname = window.location.hostname;

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/fahrzeug/:id",
        name: "fahrzeug",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "fahrzeug" */ "../views/Fahrzeug.vue"),
        props: true
    }
];

const router = new VueRouter({
    mode: "hash",
    routes
});

export default router;
