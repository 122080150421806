<template>
  <div class="c-home">
    <!-- <h1 class="text-center">Gebrauchtwagen</h1> -->
    <div v-loading="$store.state.isLoading">
      <!-- <el-row
                type="flex"
                justify="center"
                :gutter="10"
                class="margin-bottom flex-wrap"
            >
                <el-col
                    v-for="(filter, filterName) in filters"
                    :key="filterName"
                    :xs="12"
                    :sm="8"
                    :md="6"
                    :lg="4"
                > -->
      <div class="c-home__filter">
        <el-select
          v-for="(filter, filterName) in filters"
          :key="filterName"
          v-model="selectedFilters[filterName]"
          multiple
          :placeholder="filtersConfig[filterName].name"
          class="-select"
          v-show="filtersConfig[filterName].show"
        >
          <el-option
            v-for="(item, key) in filters[filterName]"
            :key="key"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <!-- </el-col>
                <el-col :xs="12" :sm="8" :md="6" :lg="4"> -->
        <filter-range
          v-for="(rangeFilter, filterName) in rangeFiltersConfig"
          :key="filterName"
          :label="rangeFilter.label"
          :min="rangeFilter.min"
          :max="rangeFilter.max"
          @input="changeRangeData(rangeFilter.valueSuffix, $event)"
          class="-range"
          @is-selected="rangeFiltersConfig[filterName].isSelected = $event"
          :toValue="$data['to' + rangeFilter.valueSuffix]"
        ></filter-range>
      </div>
      <!-- </el-col>
            </el-row> -->
      <!-- <el-row
                type="flex"
                justify="center"
                :gutter="10"
                class="margin-bottom flex-wrap"
            >
                <el-col :xs="24" :sm="12" :md="8" :lg="6">
                    Preis
                    <el-input
                        type="number"
                        min="0"
                        step="1"
                        placeholder="von"
                        v-model="fromPrice"
                        class="c-home__price-input"
                    ></el-input
                    ><el-input
                        type="number"
                        min="0"
                        step="1"
                        placeholder="bis"
                        v-model="toPrice"
                        class="c-home__price-input"
                    ></el-input>
                </el-col>
            </el-row> -->
      <div class="c-line margin-bottom">
        <span class="c-home__carcount"
          >{{ filteredCars.length }} Fahrzeuge</span
        >
      </div>
      <el-row :gutter="20" type="flex" style="flex-wrap: wrap;">
        <!-- type="flex" style="flex-wrap: wrap;" -->
        <el-col
          :xs="24"
          :sm="12"
          :md="8"
          v-for="(car, key) in filteredCars"
          :key="key"
        >
          <car-thumb :carId="car['Fahrzeug-ID']"></car-thumb>
        </el-col>
      </el-row>
      <span v-if="!$store.state.isLoading && filteredCars.length === 0"
        >Keine Fahrzeuge vorhanden. Bitte versuchen Sie einen anderen
        Filter.</span
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import { getFilters } from "@/api/cars.js";
import CarThumb from "@/components/CarThumb.vue";
import FilterRange from "@/components/FilterRange.vue";
import { convertKwToPs } from "@/utils/number";

export default {
  name: "home",
  components: { CarThumb, FilterRange },
  data() {
    return {
      filtersConfig: {
        "Marken-ID": {
          show: true,
          name: "Hersteller",
        },
        "Aufbauart-ID": {
          show: true,
          name: "Variante",
        },
        "Kraftstoff-ID": {
          show: true,
          name: "Motor",
        },
        "Getriebeart-ID": {
          show: true,
          name: "Getriebe",
        },
        Antrieb: {
          show: true,
          name: "Antrieb",
        },
        "Fahrzeugtyp-ID": {
          show: true,
          name: "Fahrzeugtyp",
        },
        "Händler-ID": {
          show: false,
          name: "Standort"
        }
      },
      filters: {},
      selectedFilters: {},
      rangeFiltersConfig: {
        KW: {
          show: true,
          label: "Leistung in PS",
          min: 0,
          max: 1,
          valueSuffix: "Power",
          isSelected: false,
        },
        KM: {
          show: true,
          label: "Kilometer",
          min: 0,
          max: 1,
          valueSuffix: "Km",
          isSelected: false,
        },
        Erstzulassung: {
          show: true,
          label: "Erstzulassung",
          min: 0,
          max: 1,
          valueSuffix: "Registration",
          isSelected: false,
        },
        Preis: {
          show: true,
          label: "Preis",
          min: 0,
          max: 1,
          valueSuffix: "Price",
          isSelected: false,
        },
      },
      fromPower: "",
      toPower: "",
      fromKm: "",
      toKm: "",
      fromRegistration: "",
      toRegistration: "",
      fromPrice: "",
      toPrice: "",
    };
  },
  computed: {
    cars() {
      let result = [];
      for (let carId in this.$store.state.cars.all) {
        result.push(this.$store.state.cars.all[carId]);
      }
      return result;
    },
    filteredCars() {
      let result = [];
      let isFilterSelected = false;
      for (let key in this.selectedFilters) {
        if (this.selectedFilters[key].length > 0) {
          isFilterSelected = true;
        }
      }
      if (isFilterSelected || this.fromPrice !== "" || this.toPrice || "") {
        result = this.cars.filter((car) => {
          for (var key in this.selectedFilters) {
            if (
              this.selectedFilters[key].length > 0 &&
              this.selectedFilters[key].includes(car[key]) === false
            ) {
              return false;
            }
          }
          for (var rangeFilterKey in this.rangeFiltersConfig) {
            let rangeFilter = this.rangeFiltersConfig[rangeFilterKey];
            if (rangeFilter.isSelected) {
              let carValue = car[rangeFilterKey];
              if (carValue) {
                if (rangeFilterKey === "KW") {
                  carValue = convertKwToPs(carValue);
                } else if (rangeFilterKey === "Erstzulassung") {
                  carValue = carValue.substring(0, 4);
                }
                if (
                  carValue < parseInt(this["from" + rangeFilter.valueSuffix]) ||
                  carValue > parseInt(this["to" + rangeFilter.valueSuffix])
                ) {
                  return false;
                }
              }
            }
          }
          return true;
        });
      } else {
        result = Object.assign([], this.cars);
      }
      return result;
    },
  },
  // watch: {
  //   cars() {
  //     this.getFilters();
  //   },
  // },
  async mounted() {
    await this.$store.dispatch("cars/getCars");
    await this.getFilters();
    if(this.$route.query.hersteller){
      this.selectedFilters["Marken-ID"] = Array.isArray(this.$route.query.hersteller) ? this.$route.query.hersteller : [this.$route.query.hersteller];
    }
    if(this.$route.query.variante){
      this.selectedFilters["Aufbauart-ID"] = Array.isArray(this.$route.query.variante) ? this.$route.query.variante : [this.$route.query.variante];
    }
    if(this.$route.query.getriebe){
      this.selectedFilters["Getriebeart-ID"] = Array.isArray(this.$route.query.getriebe) ? this.$route.query.getriebe : [this.$route.query.getriebe];
    }
    if(this.$route.query.motor){
      this.selectedFilters["Kraftstoff-ID"] = Array.isArray(this.$route.query.motor) ? this.$route.query.motor : [this.$route.query.motor];
    }
    if(this.$route.query.fahrzeugtyp){
      this.selectedFilters["Fahrzeugtyp-ID"] = Array.isArray(this.$route.query.fahrzeugtyp) ? this.$route.query.fahrzeugtyp : [this.$route.query.fahrzeugtyp];
    }
    if(this.$route.query.antrieb){
      this.selectedFilters["Antrieb"] = Array.isArray(this.$route.query.antrieb) ? this.$route.query.antrieb : [this.$route.query.antrieb];
    }
    if(this.$route.query.standort){
      this.selectedFilters["Händler-ID"] = Array.isArray(this.$route.query.standort) ? this.$route.query.standort : [this.$route.query.standort];
    }
    if(this.$route.query.preismax){
      this.toPrice = parseInt(this.$route.query.preismax);
    }
  },
  methods: {
    changeRangeData(valueName, values) {
      this["from" + valueName] = values[0];
      this["to" + valueName] = values[1];
    },
    async getFilters() {
      for (let key in this.rangeFiltersConfig) {
        let rangeFilter = this.rangeFiltersConfig[key];
        for (let car of this.cars) {
          let carValue = car[key];
          if (carValue) {
            if (key === "KW") {
              carValue = convertKwToPs(carValue);
            } else if (key === "Erstzulassung") {
              carValue = carValue.substring(0, 4);
            }
            carValue = parseInt(carValue);
            if (rangeFilter.min === 0 || rangeFilter.min > carValue) {
              rangeFilter.min = carValue;
            }
            if (rangeFilter.max < carValue) {
              rangeFilter.max = carValue;
            }
          }
        }
      }
      const response = await getFilters();
      if (response.data.Status === "Success") {
        const filters = response.data.Result;
        this.filters = {};
        this.selectedFilters = {};
        for (let filterName in filters) {
          if (
            this.filtersConfig[filterName] &&
            this.filtersConfig[filterName].show
          ) {
            for (let filterValueKey in filters[filterName]) {
              let filterValue = filters[filterName][filterValueKey];
              for (let car of this.cars) {
                if (car[filterName] === filterValue) {
                  if (this.filters[filterName]) {
                    this.$set(
                      this.filters[filterName],
                      filterValueKey,
                      filterValue
                    );
                  } else {
                    this.$set(this.filters, filterName, {
                      [filterValueKey]: filterValue,
                    });
                    this.$set(this.selectedFilters, filterName, []);
                  }
                  break;
                }
              }
            }
          }
        }
        const haendlerIDs = {};
        for (let car of this.cars) {
          if(!haendlerIDs[car["Händler-ID"]]){
            haendlerIDs[car["Händler-ID"]] = car["Händler-ID"];
          }
        }
        this.$set(this.filters, "Händler-ID", haendlerIDs);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-home {
  &__filter {
    margin-bottom: 20px;

    @media screen and (max-width: $breakpoint-sm) {
      text-align: center;
    }

    .-select,
    .-range {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }

  &__price-input {
    width: 30%;
    margin-left: 5px;
  }
  &__carcount {
    z-index: 1;
    background: white;
    padding-left: 40px;
    padding-right: 40px;
    color: grey;
  }
}
</style>
